document.addEventListener('DOMContentLoaded', () => {
  const shippingAddressCheckbox = document.getElementById('ship-to-different-address-checkbox')
  if (shippingAddressCheckbox) {
    shippingAddressCheckbox.addEventListener('change', (event) => {
      event.preventDefault()
      const shippingAddressWrapper = document.querySelector('.shipping_address')
      shippingAddressWrapper.classList.toggle('show-shipping-address')
    })
  }
})
