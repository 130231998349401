/* eslint-disable func-names */
/* eslint-disable no-undef */
import '../styles/main.scss'
import './woocommerce/add-to-cart'
import './woocommerce/cart'
import './woocommerce/checkout'

import Masonry from 'masonry-layout'
import hoverintent from 'hoverintent'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

// require('intersection-observer')

if (module.hot) module.hot.accept()

document.addEventListener('DOMContentLoaded', () => {
  // Masonry layout for archive
  //----------------------------------------------------------
  const grid = document.querySelector('.archive-wrapper')
  if (grid) {
    // eslint-disable-next-line no-unused-vars
    const msnry = new Masonry(grid, {
      itemSelector: '.archive-item',
      columnWidth: '.grid-sizer',
      percentPosition: true
    })
  }

  // Detect touch device
  //----------------------------------------------------------
  const isTouch = 'ontouchstart' in document.documentElement !== false

  // Header dropdown menus
  //----------------------------------------------------------
  const menuElements = [...document.querySelectorAll('.header-menu .menu-item-has-children')]
  const menuLinkElements = [
    ...document.querySelectorAll('.header-menu .menu-item-has-children > a')
  ]
  const openSubmenu = (element) => {
    element.classList.add('submenu-is-open')
  }
  const closeSubmenu = (element) => {
    element.classList.remove('submenu-is-open')
  }

  // Regular devices
  const opts = {
    timeout: 250,
    interval: 50
  }

  if (!isTouch) {
    document.body.classList.add('no-touch')
    menuElements.forEach((element) => {
      hoverintent(
        element,
        () => {
          openSubmenu(element)
        },
        () => {
          closeSubmenu(element)
        }
      ).options(opts)
    })
    // Prevent links on parents
    menuLinkElements.forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault()
      })
    })
  }

  // Touch devices
  if (isTouch) {
    document.body.classList.add('touch')
    menuLinkElements.forEach((element) => {
      element.addEventListener('click', (event) => {
        menuElements.forEach((node) => {
          node.classList.remove('submenu-is-open')
        })
        element.parentNode.classList.add('submenu-is-open')
        event.preventDefault()
      })
    })
  }

  // Mobile menu
  //----------------------------------------------------------
  const mobileMenu = document.getElementById('mobile-menu')
  const openMobileMenuButton = document.getElementById('open-mobile-menu')
  const closeMobileMenuButton = document.getElementById('close-mobile-menu')
  openMobileMenuButton.addEventListener(
    'touchstart',
    (event) => {
      mobileMenu.classList.add('mobile-menu-is-open')
      disableBodyScroll(mobileMenu)
      event.preventDefault()
    },
    { passive: false }
  )
  closeMobileMenuButton.addEventListener(
    'touchstart',
    (event) => {
      mobileMenu.classList.remove('mobile-menu-is-open')
      enableBodyScroll(mobileMenu)
      event.preventDefault()
    },
    { passive: false }
  )

  // Mobile dropdown menu
  //----------------------------------------------------------
  const mobileDropdownItems = [
    ...document.querySelectorAll('.mobile-menu .menu-item-has-children > a')
  ]
  const handleMobileDropdown = (event) => {
    const listItem = event.currentTarget.parentNode
    if (listItem.classList.contains('submenu-is-open')) {
      listItem.classList.remove('submenu-is-open')
    } else {
      mobileDropdownItems.forEach((item) => {
        item.parentNode.classList.remove('submenu-is-open')
      })
      listItem.classList.add('submenu-is-open')
    }
    event.preventDefault()
  }
  mobileDropdownItems.forEach((link) => {
    link.addEventListener('touchstart', handleMobileDropdown, { passive: false })
  })

  // Open search
  //----------------------------------------------------------
  const openSearchButton = document.getElementById('open-search')
  const searchForm = document.getElementById('searchform')
  openSearchButton.addEventListener('click', (event) => {
    searchForm.classList.add('search-is-open')
    event.preventDefault()
  })

  // Trigger animation when element comes into view
  //----------------------------------------------------------
  // const elements = document.querySelectorAll('.move-up-element')
  // elements.forEach((element) => {
  //   element.classList.remove('move-up-transition')

  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         element.classList.add('move-up-transition')
  //       }
  //     })
  //   })

  //   observer.observe(element)
  // })

  // Find all links with image inside, add class to prevent underline
  //----------------------------------------------------------
  const imageInsideLink = document.querySelectorAll('a > img')
  if (imageInsideLink) {
    imageInsideLink.forEach((image) => {
      image.parentElement.classList.add('no-underline')
    })
  }

  // FIXED MENU ON SCROLL
  //----------------------------------------------------------

  const elMoveUp = document.getElementById('all-the-way-up')
  window.addEventListener('scroll', () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop

    if (distanceY === 0) {
      elMoveUp.classList.remove('visible')
    } else {
      elMoveUp.classList.add('visible')
    }
  })
})

// Hide all image tooltips
//----------------------------------------------------------

jQuery(document).ready(($) => {
  $(() => {
    $('img').hover(
      function () {
        $(this).removeAttr('title')
      },
      function () {
        $(this).attr('title', 'some title')
      }
    )
  })
})

// Select all input field in Cookie conset as default
window.addEventListener('load', () => {
  const settingsbtn = document.getElementsByClassName('pixelmate-general-single-bottom')
  console.log(settingsbtn)
  if (settingsbtn.length > 0) {
    settingsbtn[0].addEventListener('click', () => {
      jQuery('#pixelmate-statistik').prop('checked', true)
      jQuery('#pixelmate-werbung').prop('checked', true)
      jQuery('#pixelmate-videos').prop('checked', true)
      jQuery('#pixelmate-externe').prop('checked', true)
    })
  }
})
