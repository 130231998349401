import Snackbar from 'node-snackbar'

document.addEventListener('DOMContentLoaded', () => {
  // Function to update the cart counter in the header section of the page
  //----------------------------------------------------------
  function updateCartCountInHeaderNew(quantity) {
    const cartInHeader = document.getElementById('header-cart')
    const cartCountInHeader = document.getElementById('header-cart-count')
    if (!cartInHeader.classList.contains('cartlink--active')) {
      cartInHeader.classList.add('cartlink--active')
    }
    if (cartInHeader && cartCountInHeader) {
      cartInHeader.classList.add('changed')
      cartCountInHeader.innerHTML =
        parseInt(cartCountInHeader.innerHTML, 10) + parseInt(quantity, 10)

      // Reset CSS animation
      setTimeout(() => {
        cartInHeader.classList.remove('changed')
      }, 200)
    }
  }

  // Function to actually add a product to the WooCommerce cart
  //----------------------------------------------------------
  const addToCartButtons = document.querySelectorAll(
    '.product-type-simple .single_add_to_cart_button'
  )

  async function addToCart(productId, quantity) {
    const response = await fetch(`/?add-to-cart=${productId}&quantity=${quantity}`)
    const url = new URL(response.url)
    const product = url.searchParams.get('add-to-cart')
    if (product !== '0') {
      Snackbar.show({
        text: 'Produkt dem <a href="/warenkorb">Warenkorb</a> hinzugefügt.',
        pos: 'bottom-right',
        backgroundColor: '#ffcc66',
        showAction: false,
        duration: 3000
      })
      console.log(response)
      if (response.ok) {
        updateCartCountInHeaderNew(quantity)
        addToCartButtons.forEach((button) => {
          button.removeAttribute('disabled')
        })
      }
    } else {
      Snackbar.show({
        text: 'Fehler beim Hinzufügen des Produkts zum Warenkorb.',
        pos: 'bottom-right',
        backgroundColor: '#b00020',
        showAction: false,
        duration: 3000
      })
    }
  }

  // Add event listener for all the "add to cart"-buttons with variations
  //----------------------------------------------------------
  const variationsAddToCartButtons = document.querySelectorAll(
    '.woocommerce-variation-add-to-cart .single_add_to_cart_button:not(.disabled)'
  )
  variationsAddToCartButtons.forEach((button) => {
    button.addEventListener('click', (event) => {
      event.preventDefault()
      const productId = button.dataset.product_id
      const variationID = document.querySelector('[name=variation_id]').value
      const quantity = document.querySelector('[name=quantity]').value
      if (variationID) {
        addToCart(variationID, quantity)
      } else if (productId) {
        addToCart(productId, quantity)
      }
    })
  })

  // Add event listener for all the "add to cart"-buttons without variations
  //----------------------------------------------------------
  addToCartButtons.forEach((button) => {
    button.addEventListener('click', (event) => {
      event.preventDefault()
      const productId = button.value
      const quantity = document.querySelector('[name=quantity]').value
      if (productId) {
        addToCart(productId, quantity)
        button.setAttribute('disabled', 'true')
      }
    })
  })
})
