import Snackbar from 'node-snackbar'

document.addEventListener('DOMContentLoaded', () => {
  // Function to update the cart counter in the header section of the page
  //----------------------------------------------------------
  function updateCartCountInHeader() {
    const cartCountInHeader = document.getElementById('header-cart-count')
    if (cartCountInHeader) {
      const items = document.querySelectorAll('.cart-item')
      let total = 0
      items.forEach((item) => {
        const quantity = parseInt(item.dataset.quantity, 10)
        total += quantity
      })
      cartCountInHeader.innerHTML = total
    }
  }

  // Function to recalculate all prices and updated them in the DOM
  //----------------------------------------------------------
  async function updateDOMprices(key) {
    // Recalc total price of product
    if (key) {
      const product = document.querySelector(`[data-key="${key}"]`)
      const productQty = product.dataset.quantity
      const productPrice = product.dataset.price
      const productTotalPrice = product.querySelector('.item-total-element')
      const newProductTotalPrice = parseInt(productQty * productPrice, 10)
      productTotalPrice.innerHTML = `${newProductTotalPrice}.—`
    }

    // Reload Total Price
    let doc = 0
    await fetch(`/?wc-ajax=get_cart_totals`)
      .then((response) => response.text())
      .then((html) => {
        const parser = new DOMParser()
        doc = parser.parseFromString(html, 'text/html')
      })
    const cartTotalContainer = document.querySelector('.cart-total')
    const oldCartTotal = document.querySelector('.cart-total-price')
    const cartTotal = doc.querySelector('.order-total .amount')
    cartTotal.classList.add('cart-total-price')
    cartTotal.classList.remove('woocommerce-Price-amount')
    cartTotal.classList.remove('amount')
    oldCartTotal.remove()
    cartTotalContainer.appendChild(cartTotal)

    // Reload Subtotal Price
    const cartSubtotalContainer = document.querySelector('.subtotal_price .amount')
    const oldCartSubtotal = document.querySelector('.subtotal_price .amount bdi')
    const cartSubtotal = doc.querySelector('.cart-subtotal .amount bdi')
    oldCartSubtotal.remove()
    cartSubtotalContainer.appendChild(cartSubtotal)

    // Reload Shipping Methods
    const cartShippingContainer = document.querySelector('.cart-shipping')
    const cartShipping = document.querySelector('.cart-shipping li:last-child')
    const cartShippingPrice = document.querySelector('.cart-shipping .amount bdi')
    const shippingMethods = doc.querySelector('#shipping_method li:last-child')
    const shippingMethodsPrice = doc.querySelector('#shipping_method .amount bdi')

    if (cartShippingPrice.innerHTML !== shippingMethodsPrice.innerHTML) {
      if (cartShipping) {
        cartShipping.remove()
      }
      cartShippingContainer.appendChild(shippingMethods)
    }
  }

  // Function to actually update amount of item in the WooCommerce cart
  //----------------------------------------------------------
  const quantityButtons = document.querySelectorAll('.set-quantity-cart')

  async function updateItemInCart(key, newValue) {
    const data = new FormData()
    data.append('action', 'set_quantity') // WP Ajax action
    data.append('key', key)
    data.append('quantity', newValue)
    const response = await fetch('/wp-admin/admin-ajax.php', {
      method: 'post',
      body: data
    })
    if (response.ok) {
      Snackbar.show({
        text: 'Anzahl geändert.',
        pos: 'bottom-right',
        backgroundColor: '#ffcc66',
        showAction: false,
        duration: 3000
      })
      updateDOMprices(key)
      quantityButtons.forEach((button) => {
        button.removeAttribute('disabled')
      })
    } else {
      console.error('Fehler beim Ändern der Anzahl.')
      Snackbar.show({
        text: 'Fehler beim Ändern der Anzahl.',
        pos: 'bottom-right',
        backgroundColor: '#b00020',
        showAction: false,
        duration: 3000
      })
    }
  }

  // Function to actually delete item in the WooCommerce cart
  //----------------------------------------------------------
  async function deleteItemInCart(key, nonce) {
    const response = await fetch(`/?remove_item=${key}&_wpnonce=${nonce}`)
    if (response.ok) {
      Snackbar.show({
        text: 'Das Produkt wurde aus dem Warenkorb entfernt.',
        pos: 'bottom-right',
        backgroundColor: '#ffcc66',
        showAction: false,
        duration: 3000
      })
      updateDOMprices()
    } else {
      console.error('Fehler beim Entfernen des Produkts aus dem Warenkorb.')
      Snackbar.show({
        text: 'Fehler beim Entfernen des Produkts aus dem Warenkorb.',
        pos: 'bottom-right',
        backgroundColor: '#b00020',
        showAction: false,
        duration: 3000
      })
    }
  }

  // Add event listener for all the "delete item"-buttons in the cart
  //----------------------------------------------------------
  const deleteButtons = document.querySelectorAll('.delete-item-cart')
  deleteButtons.forEach((button) => {
    button.addEventListener('click', (event) => {
      event.preventDefault()
      const item = button.closest('.cart-item')
      const { key } = item.dataset
      const nonce = document.getElementById('_wpnonce').value
      item.remove()
      updateCartCountInHeader()
      deleteItemInCart(key, nonce)
    })
  })

  // Add event listener for change of shipping option in the cart
  //----------------------------------------------------------
  const shippingMethods = document.querySelector('.cart-shipping')
  if (shippingMethods) {
    shippingMethods.addEventListener('change', (e) => {
      if (e.target && e.target.nodeName === 'INPUT') {
        setTimeout(() => {
          updateDOMprices()
        }, 250)
      }
    })
  }

  // Add event listener for all the "set quantity"-buttons in the cart
  //----------------------------------------------------------
  quantityButtons.forEach((button) => {
    button.addEventListener('click', (event) => {
      event.preventDefault()
      const item = button.closest('.cart-item')
      const { key } = item.dataset
      const { type } = button.dataset
      const numberField =
        type === 'decrease' ? button.nextElementSibling : button.previousElementSibling
      const currentValue = parseInt(numberField.innerHTML, 10)
      button.setAttribute('disabled', 'true')

      // Calculate new value
      const newValue = type === 'decrease' ? currentValue - 1 : currentValue + 1
      if (newValue > 0) {
        numberField.innerHTML = newValue
        item.dataset.quantity = newValue

        numberField.classList.add('changed')

        updateCartCountInHeader()
        updateItemInCart(key, newValue)

        // Reset CSS animation
        setTimeout(() => {
          numberField.classList.remove('changed')
        }, 200)
      } else if (newValue === 0) {
        const nonce = document.getElementById('_wpnonce').value
        item.remove()
        updateCartCountInHeader()
        updateDOMprices(nonce)
        deleteItemInCart(key, nonce)
      }
    })
  })
})
